<template>
    <div id="standort">
        <div class="intro-text">{{ __('project.standort_erklaerung') }}</div>

        <table v-if="showMikrolage" class="table mt-3" id="mikrolage-table">
            <!-- Gelände -->
            <tr>
                <td style="font-weight: 600;">{{ __('project.gelaende') }}</td>
                <td></td>
                <td></td>
            </tr>
            <tr v-if="SUN_SHINE_DURATION_SUMMER != null">
                <td>{{ __('project.SUN_SHINE_DURATION_SUMMER') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: SUN_SHINE_DURATION_SUMMER < 1.5}"></div>
                    <div class="two" :class="{active: SUN_SHINE_DURATION_SUMMER > 1.49 && SUN_SHINE_DURATION_SUMMER < 2.5}"></div>
                    <div class="three" :class="{active: SUN_SHINE_DURATION_SUMMER > 2.49 && SUN_SHINE_DURATION_SUMMER < 3.5}"></div>
                    <div class="four" :class="{active: SUN_SHINE_DURATION_SUMMER > 3.49 && SUN_SHINE_DURATION_SUMMER < 4.5}"></div>
                    <div class="five" :class="{active: SUN_SHINE_DURATION_SUMMER > 4.49}"></div>
                </td>
                <td>{{ SUN_SHINE_DURATION_SUMMER }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="SUN_SHINE_DURATION_WINTER != null">
                <td>{{ __('project.SUN_SHINE_DURATION_WINTER') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: SUN_SHINE_DURATION_WINTER < 1.5}"></div>
                    <div class="two" :class="{active: SUN_SHINE_DURATION_WINTER > 1.49 && SUN_SHINE_DURATION_WINTER < 2.5}"></div>
                    <div class="three" :class="{active: SUN_SHINE_DURATION_WINTER > 2.49 && SUN_SHINE_DURATION_WINTER < 3.5}"></div>
                    <div class="four" :class="{active: SUN_SHINE_DURATION_WINTER > 3.49 && SUN_SHINE_DURATION_WINTER < 4.5}"></div>
                    <div class="five" :class="{active: SUN_SHINE_DURATION_WINTER > 4.49}"></div>
                </td>
                <td>{{ SUN_SHINE_DURATION_WINTER }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="TERRAIN != null">
                <td>{{ __('project.TERRAIN') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: TERRAIN < 1.5}"></div>
                    <div class="two" :class="{active: TERRAIN > 1.49 && TERRAIN < 2.5}"></div>
                    <div class="three" :class="{active: TERRAIN > 2.49 && TERRAIN < 3.5}"></div>
                    <div class="four" :class="{active: TERRAIN > 3.49 && TERRAIN < 4.5}"></div>
                    <div class="five" :class="{active: TERRAIN > 4.49}"></div>
                </td>
                <td>{{ TERRAIN }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="SLOPE_INCLINATION != null">
                <td>{{ __('project.SLOPE_INCLINATION') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: SLOPE_INCLINATION < 1.5}"></div>
                    <div class="two" :class="{active: SLOPE_INCLINATION > 1.49 && SLOPE_INCLINATION < 2.5}"></div>
                    <div class="three" :class="{active: SLOPE_INCLINATION > 2.49 && SLOPE_INCLINATION < 3.5}"></div>
                    <div class="four" :class="{active: SLOPE_INCLINATION > 3.49 && SLOPE_INCLINATION < 4.5}"></div>
                    <div class="five" :class="{active: SLOPE_INCLINATION > 4.49}"></div>
                </td>
                <td>{{ SLOPE_INCLINATION }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="LAKE_VIEW != null">
                <td>{{ __('project.LAKE_VIEW') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: LAKE_VIEW < 1.5}"></div>
                    <div class="two" :class="{active: LAKE_VIEW > 1.49 && LAKE_VIEW < 2.5}"></div>
                    <div class="three" :class="{active: LAKE_VIEW > 2.49 && LAKE_VIEW < 3.5}"></div>
                    <div class="four" :class="{active: LAKE_VIEW > 3.49 && LAKE_VIEW < 4.5}"></div>
                    <div class="five" :class="{active: LAKE_VIEW > 4.49}"></div>
                </td>
                <td>{{ LAKE_VIEW }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="MOUNTAIN_VIEW != null">
                <td>{{ __('project.MOUNTAIN_VIEW') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: MOUNTAIN_VIEW < 1.5}"></div>
                    <div class="two" :class="{active: MOUNTAIN_VIEW > 1.49 && MOUNTAIN_VIEW < 2.5}"></div>
                    <div class="three" :class="{active: MOUNTAIN_VIEW > 2.49 && MOUNTAIN_VIEW < 3.5}"></div>
                    <div class="four" :class="{active: MOUNTAIN_VIEW > 3.49 && MOUNTAIN_VIEW < 4.5}"></div>
                    <div class="five" :class="{active: MOUNTAIN_VIEW > 4.49}"></div>
                </td>
                <td>{{ MOUNTAIN_VIEW }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <!-- Infrastruktur -->
            <tr>
                <td style="font-weight: 600; padding-top: 15px;">{{ __('project.infrastruktur') }}</td>
                <td></td>
                <td></td>
            </tr>
            <tr v-if="INFRASTRUCTURE != null">
                <td>{{ __('project.infrastruktur') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: INFRASTRUCTURE < 1.5}"></div>
                    <div class="two" :class="{active: INFRASTRUCTURE > 1.49 && INFRASTRUCTURE < 2.5}"></div>
                    <div class="three" :class="{active: INFRASTRUCTURE > 2.49 && INFRASTRUCTURE < 3.5}"></div>
                    <div class="four" :class="{active: INFRASTRUCTURE > 3.49 && INFRASTRUCTURE < 4.5}"></div>
                    <div class="five" :class="{active: INFRASTRUCTURE > 4.49}"></div>
                </td>
                <td>{{ INFRASTRUCTURE }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="DISTANCE_BUS_STOP != null">
                <td>{{ __('project.DISTANCE_BUS_STOP') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: DISTANCE_BUS_STOP < 1.5}"></div>
                    <div class="two" :class="{active: DISTANCE_BUS_STOP > 1.49 && DISTANCE_BUS_STOP < 2.5}"></div>
                    <div class="three" :class="{active: DISTANCE_BUS_STOP > 2.49 && DISTANCE_BUS_STOP < 3.5}"></div>
                    <div class="four" :class="{active: DISTANCE_BUS_STOP > 3.49 && DISTANCE_BUS_STOP < 4.5}"></div>
                    <div class="five" :class="{active: DISTANCE_BUS_STOP > 4.49}"></div>
                </td>
                <td>{{ DISTANCE_BUS_STOP }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="DISTANCE_RECREATION_AREA != null">
                <td>{{ __('project.DISTANCE_RECREATION_AREA') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: DISTANCE_RECREATION_AREA < 1.5}"></div>
                    <div class="two" :class="{active: DISTANCE_RECREATION_AREA > 1.49 && DISTANCE_RECREATION_AREA < 2.5}"></div>
                    <div class="three" :class="{active: DISTANCE_RECREATION_AREA > 2.49 && DISTANCE_RECREATION_AREA < 3.5}"></div>
                    <div class="four" :class="{active: DISTANCE_RECREATION_AREA > 3.49 && DISTANCE_RECREATION_AREA < 4.5}"></div>
                    <div class="five" :class="{active: DISTANCE_RECREATION_AREA > 4.49}"></div>
                </td>
                <td>{{ DISTANCE_RECREATION_AREA }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="DISTANCE_SCHOOL != null">
                <td>{{ __('project.DISTANCE_SCHOOL') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: DISTANCE_SCHOOL < 1.5}"></div>
                    <div class="two" :class="{active: DISTANCE_SCHOOL > 1.49 && DISTANCE_SCHOOL < 2.5}"></div>
                    <div class="three" :class="{active: DISTANCE_SCHOOL > 2.49 && DISTANCE_SCHOOL < 3.5}"></div>
                    <div class="four" :class="{active: DISTANCE_SCHOOL > 3.49 && DISTANCE_SCHOOL < 4.5}"></div>
                    <div class="five" :class="{active: DISTANCE_SCHOOL > 4.49}"></div>
                </td>
                <td>{{ DISTANCE_SCHOOL }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="PUBLIC_TRANSPORT_GRADE != null">
                <td>{{ __('project.PUBLIC_TRANSPORT_GRADE') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: PUBLIC_TRANSPORT_GRADE < 1.5}"></div>
                    <div class="two" :class="{active: PUBLIC_TRANSPORT_GRADE > 1.49 && PUBLIC_TRANSPORT_GRADE < 2.5}"></div>
                    <div class="three" :class="{active: PUBLIC_TRANSPORT_GRADE > 2.49 && PUBLIC_TRANSPORT_GRADE < 3.5}"></div>
                    <div class="four" :class="{active: PUBLIC_TRANSPORT_GRADE > 3.49 && PUBLIC_TRANSPORT_GRADE < 4.5}"></div>
                    <div class="five" :class="{active: PUBLIC_TRANSPORT_GRADE > 4.49}"></div>
                </td>
                <td>{{ PUBLIC_TRANSPORT_GRADE }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="DISTANCE_CENTER != null">
                <td>{{ __('project.DISTANCE_CENTER') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: DISTANCE_CENTER < 1.5}"></div>
                    <div class="two" :class="{active: DISTANCE_CENTER > 1.49 && DISTANCE_CENTER < 2.5}"></div>
                    <div class="three" :class="{active: DISTANCE_CENTER > 2.49 && DISTANCE_CENTER < 3.5}"></div>
                    <div class="four" :class="{active: DISTANCE_CENTER > 3.49 && DISTANCE_CENTER < 4.5}"></div>
                    <div class="five" :class="{active: DISTANCE_CENTER > 4.49}"></div>
                </td>
                <td>{{ DISTANCE_CENTER }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="DISTANCE_SHOPPING != null">
                <td>{{ __('project.DISTANCE_SHOPPING') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: DISTANCE_SHOPPING < 1.5}"></div>
                    <div class="two" :class="{active: DISTANCE_SHOPPING > 1.49 && DISTANCE_SHOPPING < 2.5}"></div>
                    <div class="three" :class="{active: DISTANCE_SHOPPING > 2.49 && DISTANCE_SHOPPING < 3.5}"></div>
                    <div class="four" :class="{active: DISTANCE_SHOPPING > 3.49 && DISTANCE_SHOPPING < 4.5}"></div>
                    <div class="five" :class="{active: DISTANCE_SHOPPING > 4.49}"></div>
                </td>
                <td>{{ DISTANCE_SHOPPING }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <!-- Imissionen -->
            <tr>
                <td style="font-weight: 600; padding-top: 15px;">{{ __('project.imissionen') }}</td>
                <td></td>
                <td></td>
            </tr>
            <tr v-if="DISTANCE_RADIO_ANTENNA != null">
                <td>{{ __('project.DISTANCE_RADIO_ANTENNA') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: DISTANCE_RADIO_ANTENNA < 1.5}"></div>
                    <div class="two" :class="{active: DISTANCE_RADIO_ANTENNA > 1.49 && DISTANCE_RADIO_ANTENNA < 2.5}"></div>
                    <div class="three" :class="{active: DISTANCE_RADIO_ANTENNA > 2.49 && DISTANCE_RADIO_ANTENNA < 3.5}"></div>
                    <div class="four" :class="{active: DISTANCE_RADIO_ANTENNA > 3.49 && DISTANCE_RADIO_ANTENNA < 4.5}"></div>
                    <div class="five" :class="{active: DISTANCE_RADIO_ANTENNA > 4.49}"></div>
                </td>
                <td>{{ DISTANCE_RADIO_ANTENNA }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="DISTANCE_NUCLEAR_POWER != null">
                <td>{{ __('project.DISTANCE_NUCLEAR_POWER') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: DISTANCE_NUCLEAR_POWER < 1.5}"></div>
                    <div class="two" :class="{active: DISTANCE_NUCLEAR_POWER > 1.49 && DISTANCE_NUCLEAR_POWER < 2.5}"></div>
                    <div class="three" :class="{active: DISTANCE_NUCLEAR_POWER > 2.49 && DISTANCE_NUCLEAR_POWER < 3.5}"></div>
                    <div class="four" :class="{active: DISTANCE_NUCLEAR_POWER > 3.49 && DISTANCE_NUCLEAR_POWER < 4.5}"></div>
                    <div class="five" :class="{active: DISTANCE_NUCLEAR_POWER > 4.49}"></div>
                </td>
                <td>{{ DISTANCE_NUCLEAR_POWER }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="DISTANCE_HIGH_VOLTAGE_POWER_LINE != null">
                <td>{{ __('project.DISTANCE_HIGH_VOLTAGE_POWER_LINE') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: DISTANCE_HIGH_VOLTAGE_POWER_LINE < 1.5}"></div>
                    <div class="two" :class="{active: DISTANCE_HIGH_VOLTAGE_POWER_LINE > 1.49 && DISTANCE_HIGH_VOLTAGE_POWER_LINE < 2.5}"></div>
                    <div class="three" :class="{active: DISTANCE_HIGH_VOLTAGE_POWER_LINE > 2.49 && DISTANCE_HIGH_VOLTAGE_POWER_LINE < 3.5}"></div>
                    <div class="four" :class="{active: DISTANCE_HIGH_VOLTAGE_POWER_LINE > 3.49 && DISTANCE_HIGH_VOLTAGE_POWER_LINE < 4.5}"></div>
                    <div class="five" :class="{active: DISTANCE_HIGH_VOLTAGE_POWER_LINE > 4.49}"></div>
                </td>
                <td>{{ DISTANCE_HIGH_VOLTAGE_POWER_LINE }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="IMMISSION_TRAIN_DAY != null">
                <td>{{ __('project.IMMISSION_TRAIN_DAY') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: IMMISSION_TRAIN_DAY < 1.5}"></div>
                    <div class="two" :class="{active: IMMISSION_TRAIN_DAY > 1.49 && IMMISSION_TRAIN_DAY < 2.5}"></div>
                    <div class="three" :class="{active: IMMISSION_TRAIN_DAY > 2.49 && IMMISSION_TRAIN_DAY < 3.5}"></div>
                    <div class="four" :class="{active: IMMISSION_TRAIN_DAY > 3.49 && IMMISSION_TRAIN_DAY < 4.5}"></div>
                    <div class="five" :class="{active: IMMISSION_TRAIN_DAY > 4.49}"></div>
                </td>
                <td>{{ IMMISSION_TRAIN_DAY }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="IMMISSION_TRAIN_NIGHT != null">
                <td>{{ __('project.IMMISSION_TRAIN_NIGHT') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: IMMISSION_TRAIN_NIGHT < 1.5}"></div>
                    <div class="two" :class="{active: IMMISSION_TRAIN_NIGHT > 1.49 && IMMISSION_TRAIN_NIGHT < 2.5}"></div>
                    <div class="three" :class="{active: IMMISSION_TRAIN_NIGHT > 2.49 && IMMISSION_TRAIN_NIGHT < 3.5}"></div>
                    <div class="four" :class="{active: IMMISSION_TRAIN_NIGHT > 3.49 && IMMISSION_TRAIN_NIGHT < 4.5}"></div>
                    <div class="five" :class="{active: IMMISSION_TRAIN_NIGHT > 4.49}"></div>
                </td>
                <td>{{ IMMISSION_TRAIN_NIGHT }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="IMMISSION_STREET_DAY != null">
                <td>{{ __('project.IMMISSION_STREET_DAY') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: IMMISSION_STREET_DAY < 1.5}"></div>
                    <div class="two" :class="{active: IMMISSION_STREET_DAY > 1.49 && IMMISSION_STREET_DAY < 2.5}"></div>
                    <div class="three" :class="{active: IMMISSION_STREET_DAY > 2.49 && IMMISSION_STREET_DAY < 3.5}"></div>
                    <div class="four" :class="{active: IMMISSION_STREET_DAY > 3.49 && IMMISSION_STREET_DAY < 4.5}"></div>
                    <div class="five" :class="{active: IMMISSION_STREET_DAY > 4.49}"></div>
                </td>
                <td>{{ IMMISSION_STREET_DAY }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="IMMISSION_STREET_NIGHT != null">
                <td>{{ __('project.IMMISSION_STREET_NIGHT') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: IMMISSION_STREET_NIGHT < 1.5}"></div>
                    <div class="two" :class="{active: IMMISSION_STREET_NIGHT > 1.49 && IMMISSION_STREET_NIGHT < 2.5}"></div>
                    <div class="three" :class="{active: IMMISSION_STREET_NIGHT > 2.49 && IMMISSION_STREET_NIGHT < 3.5}"></div>
                    <div class="four" :class="{active: IMMISSION_STREET_NIGHT > 3.49 && IMMISSION_STREET_NIGHT < 4.5}"></div>
                    <div class="five" :class="{active: IMMISSION_STREET_NIGHT > 4.49}"></div>
                </td>
                <td>{{ IMMISSION_STREET_NIGHT }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="IMMISSION_AIRCRAFT_DAY != null">
                <td>{{ __('project.IMMISSION_AIRCRAFT_DAY') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: IMMISSION_AIRCRAFT_DAY < 1.5}"></div>
                    <div class="two" :class="{active: IMMISSION_AIRCRAFT_DAY > 1.49 && IMMISSION_AIRCRAFT_DAY < 2.5}"></div>
                    <div class="three" :class="{active: IMMISSION_AIRCRAFT_DAY > 2.49 && IMMISSION_AIRCRAFT_DAY < 3.5}"></div>
                    <div class="four" :class="{active: IMMISSION_AIRCRAFT_DAY > 3.49 && IMMISSION_AIRCRAFT_DAY < 4.5}"></div>
                    <div class="five" :class="{active: IMMISSION_AIRCRAFT_DAY > 4.49}"></div>
                </td>
                <td>{{ IMMISSION_AIRCRAFT_DAY }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="IMMISSION_AIRCRAFT_NIGHT != null">
                <td>{{ __('project.IMMISSION_AIRCRAFT_NIGHT') }}</td>
                <td class="standort-grafik">
                    <div class="one" :class="{active: IMMISSION_AIRCRAFT_NIGHT < 1.5}"></div>
                    <div class="two" :class="{active: IMMISSION_AIRCRAFT_NIGHT > 1.49 && IMMISSION_AIRCRAFT_NIGHT < 2.5}"></div>
                    <div class="three" :class="{active: IMMISSION_AIRCRAFT_NIGHT > 2.49 && IMMISSION_AIRCRAFT_NIGHT < 3.5}"></div>
                    <div class="four" :class="{active: IMMISSION_AIRCRAFT_NIGHT > 3.49 && IMMISSION_AIRCRAFT_NIGHT < 4.5}"></div>
                    <div class="five" :class="{active: IMMISSION_AIRCRAFT_NIGHT > 4.49}"></div>
                </td>
                <td>{{ IMMISSION_AIRCRAFT_NIGHT }} <span style="opacity: 0.6;">/ 5</span></td>
            </tr>
            <tr v-if="PLANNING_ZONE != null">
                <td style="font-weight: 600; padding-top: 15px;">{{ __('project.zonierung') }}</td>
                <td></td>
                <td></td>
            </tr>
            <tr v-if="PLANNING_ZONE != null">
                <td>{{ __('project.PLANNING_ZONE') }}</td>
                <td>
                    {{ PLANNING_ZONE }}
                </td>
                <td></td>
            </tr>
        </table>
        <!-- Fallback Mikrolage -->
        <div v-else>
            <p class="intro-text">
                {{ __('project.standort_fail') }}
            </p>
        </div>

        <div class="points-box mb-2">
            <p class="title mb-2" style="position:relative; width:max-content;">
                {{ __('project.lage_gemeinde') }}
            </p>
            <p class="points mb-0">{{ formData.standort_rating }} {{ __('project.von') }} 5</p>
        </div>
    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        project_data: {
            type: String
        }
    },
    data() {
        return {
            formData: {
                standort_rating: 0,
            },
            projectData: {},
            showMikrolage: true,
            TERRAIN: '',
            MOUNTAIN_VIEW: '',
            LAKE_VIEW: '',
            SLOPE_INCLINATION: '',
            DISTANCE_CENTER: '',
            DISTANCE_BUS_STOP: '',
            DISTANCE_RECREATION_AREA: '',
            DISTANCE_SCHOOL: '',
            PUBLIC_TRANSPORT_GRADE: '',
            SUN_SHINE_DURATION_SUMMER: '',
            SUN_SHINE_DURATION_WINTER: '',
            INFRASTRUCTURE: '',
            DISTANCE_SHOPPING: '',
            IMMISSION_TRAIN_DAY: '',
            IMMISSION_TRAIN_NIGHT: '',
            IMMISSION_STREET_DAY: '',
            IMMISSION_STREET_NIGHT: '',
            DISTANCE_RADIO_ANTENNA: '',
            DISTANCE_NUCLEAR_POWER: '',
            DISTANCE_HIGH_VOLTAGE_POWER_LINE: '',
            IMMISSION_AIRCRAFT_DAY: '',
            IMMISSION_AIRCRAFT_NIGHT: '',
            PLANNING_ZONE: ''
        }
    },
    mounted() {

        this.projectData = JSON.parse(this.project_data);
        this.formData.standort_rating = this.projectData.standort_rating;
        store.standort_rating = this.projectData.standort_rating;

        console.log("Standort projectData: ", this.projectData);
        console.log("Standort projectData.mikrolage: ", this.projectData.mikrolage);

        // Kein Rating - Tabelle ausblenden und Meldung anzeigen
        if(this.projectData.mikrolage.grade == 0) {
            this.showMikrolage = false;
        }

        this.TERRAIN = this.projectData.mikrolage.TERRAIN;
        this.MOUNTAIN_VIEW = this.projectData.mikrolage.MOUNTAIN_VIEW;
        this.LAKE_VIEW = this.projectData.mikrolage.LAKE_VIEW;
        this.SLOPE_INCLINATION = this.projectData.mikrolage.SLOPE_INCLINATION;
        this.DISTANCE_CENTER = this.projectData.mikrolage.DISTANCE_CENTER;
        this.DISTANCE_BUS_STOP = this.projectData.mikrolage.DISTANCE_BUS_STOP;
        this.DISTANCE_RECREATION_AREA = this.projectData.mikrolage.DISTANCE_RECREATION_AREA;
        this.DISTANCE_SCHOOL = this.projectData.mikrolage.DISTANCE_SCHOOL;
        this.PUBLIC_TRANSPORT_GRADE = this.projectData.mikrolage.PUBLIC_TRANSPORT_GRADE;
        this.SUN_SHINE_DURATION_SUMMER = this.projectData.mikrolage.SUN_SHINE_DURATION_SUMMER;
        this.SUN_SHINE_DURATION_WINTER = this.projectData.mikrolage.SUN_SHINE_DURATION_WINTER;
        this.INFRASTRUCTURE = this.projectData.mikrolage.INFRASTRUCTURE;
        this.DISTANCE_SHOPPING = this.projectData.mikrolage.DISTANCE_SHOPPING;
        this.IMMISSION_TRAIN_DAY = this.projectData.mikrolage.IMMISSION_TRAIN_DAY;
        this.IMMISSION_TRAIN_NIGHT = this.projectData.mikrolage.IMMISSION_TRAIN_NIGHT;
        this.IMMISSION_STREET_DAY = this.projectData.mikrolage.IMMISSION_STREET_DAY;
        this.IMMISSION_STREET_NIGHT = this.projectData.mikrolage.IMMISSION_STREET_NIGHT;
        this.DISTANCE_RADIO_ANTENNA = this.projectData.mikrolage.DISTANCE_RADIO_ANTENNA;
        this.DISTANCE_NUCLEAR_POWER = this.projectData.mikrolage.DISTANCE_NUCLEAR_POWER;
        this.DISTANCE_HIGH_VOLTAGE_POWER_LINE = this.projectData.mikrolage.DISTANCE_HIGH_VOLTAGE_POWER_LINE;
        this.IMMISSION_AIRCRAFT_DAY = this.projectData.mikrolage.IMMISSION_AIRCRAFT_DAY;
        this.IMMISSION_AIRCRAFT_NIGHT = this.projectData.mikrolage.IMMISSION_AIRCRAFT_NIGHT;
        this.PLANNING_ZONE = this.projectData.mikrolage.PLANNING_ZONE;

        // Workaround Übersetzung des Wertes der API (kommt immer auf DE)
        if(this.PLANNING_ZONE == 'Wohnzone' && window._locale == 'fr') {
            this.PLANNING_ZONE = 'Zone résidentielle';
        }

        // this.formData.standort_rating = projectData.standort_rating ?? 0;
        // console.log(this.formData.standort_rating);

        // this.changeRating();
    },
    methods: {
        // changeRating: function() {
        //     this.formData.standort_rating = 0;
        //     store.standort_rating = this.formData.standort_rating;
        // }
    }
}
</script>
