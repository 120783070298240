<template>
    <div id="zertifikat">
        <div class="intro-text">{{ __('project.zertifikat_erklaerung') }}</div>
        <div class="block">
            <div class="zusammenfassung">
                <!--
                    <div class="main-box">
                        <div>{{ __('project.nachhaltigkeits_stufe') }}</div>
                        <div>{{ nachhaltigkeit }}</div>
                    </div>
                -->

                <h3>{{ __('project.nachhaltigkeits_stufe') }}</h3>
                <div>
                    <p class="mt-3" v-if="locale == 'fr'">{{ __('project.sie_haben_stufe') }} {{ nachhaltigkeit }}.</p>
                    <p class="mt-3" v-else>{{ __('project.sie_haben_stufe') }} {{ nachhaltigkeit }} {{ __('project.erreicht') }}.</p>
                    <!--
                        <div class="mt-2">
                            <img v-for="n in parseInt(anzahlBirds)" :key="n" src="/images/abs_bird.jpg" width="20" style="margin-right: 5px;">
                        </div>
                    -->

                    <!-- <img :class="{opacity025: anzahlBirds < 1}" src="/images/abs_bird.jpg" width="20" style="margin-right: 7px;">
                    <img :class="{opacity025: anzahlBirds < 2}" src="/images/abs_bird.jpg" width="20" style="margin-right: 7px;">
                    <img :class="{opacity025: anzahlBirds < 3}" src="/images/abs_bird.jpg" width="20" style="margin-right: 7px;"> -->
                    <img :class="{opacity025: anzahlBirds < 1}" src="/images/bird_ranking_b.svg" width="30" style="margin-right: 7px;">
                    <img :class="{opacity025: anzahlBirds < 2}" src="/images/bird_ranking_b.svg" width="30" style="margin-right: 7px;">
                    <img :class="{opacity025: anzahlBirds < 3}" src="/images/bird_ranking_b.svg" width="30" style="margin-right: 7px;">
                </div>
                <!-- <p class="mt-3" style="font-size: 1rem; opacity: 0.75;">
                    Die Bewertung reicht von 0 bis 3 Vögel.
                </p> -->

                <!--
                    <div class="text mt-5">
                        <p>{{ __('project.erreichte_punkte') }}</p>
                    </div>
                -->
                <h3 class="mt-5">{{ __('project.erreichte_punkte') }}</h3>

                <div class="rating-box" style="margin-top:8px;">
                    <div class="top">
                        <div>{{ __('project.umwelt') }}</div>
                    </div>
                    <div class="inner">
                        <div class="item">
                            <div>{{ __('project.betriebsenergie') }}</div>
                            <div>{{ projectDataJson.betriebsenergie_rating }} / 10</div>
                        </div>
                        <div class="item">
                            <div>{{ __('project.bauoekologie') }}</div>
                            <div>{{ projectDataJson.bauoekologie_rating }} / 10</div>
                        </div>
                    </div>
                </div>
                <div class="rating-box">
                    <div class="top">
                        <div>{{ __('project.gesellschaft') }}</div>
                    </div>
                    <div class="inner">
                        <div class="item">
                            <div>{{ __('project.standort') }} & {{ __('project.zersiedlung') }}</div>
                            <div>{{ projectDataJson.standort_rating }} / 10</div>
                        </div>
                        <!-- <div class="item">
                            <div>{{ __('project.zersiedlung') }}</div>
                            <div>{{ projectDataJson.zersiedlung_rating }} / 5</div>
                        </div> -->
                        <div class="item">
                            <div>{{ __('project.nutzung') }}</div>
                            <div>{{ projectDataJson.nutzung_rating }} / 10</div>
                        </div>
                    </div>
                </div>
                <div class="rating-box">
                    <div class="top">
                        <div>{{ __('project.oekonomie') }}</div>
                    </div>
                    <div class="inner">
                        <div class="item">
                            <div>{{ __('project.wirtschaftlichkeit_auswirkungen') }}</div>
                            <div>{{ projectDataJson.wirtschaftlichkeit_rating }} / 10</div>
                        </div>
                    </div>
                </div>
                <div class="rating-box">
                    <div class="top">
                        <div>{{ __('project.weitere') }}</div>
                    </div>
                    <div class="inner">
                        <div class="item">
                            <div>{{ __('project.innovation') }}</div>
                            <div>{{ projectDataJson.innovation_rating }} / 10</div>
                        </div>
                    </div>
                </div>

                <div class="main-box mt-4">
                    <div>{{ __('project.total') }}</div>
                    <div>{{ total }} / 60</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        project_data: {
            type: String
        }
    },
    data() {
        return {
            nachhaltigkeit: '',
            total: 0,
            anzahlBirds: 0,
            locale: window._locale
        }
    },
    computed: {
        projectDataJson() {
            if(!this.project_data) {
                return false;
            } else {
                return JSON.parse(this.project_data);
            }
        },
    },
    mounted() {

        this.nachhaltigkeit = this.projectDataJson.nachhaltigkeit ?? 0;

        this.projectDataJson.betriebsenergie_rating = Math.round((parseFloat(this.projectDataJson.betriebsenergie_rating) + Number.EPSILON) * 10) / 10;
        this.projectDataJson.bauoekologie_rating = Math.round((parseFloat(this.projectDataJson.bauoekologie_rating) + Number.EPSILON) * 10) / 10;
        // Standort und Zersiedlung soll ein Rating sein

        let zersiedlungsrating = this.projectDataJson.zersiedlung_rating;
        if(this.projectDataJson.zersiedlung_rating_definitiv != null) {
            zersiedlungsrating = this.projectDataJson.zersiedlung_rating_definitiv;
        }

        this.projectDataJson.standort_rating = Math.round((parseFloat(this.projectDataJson.standort_rating) + zersiedlungsrating + Number.EPSILON) * 10) / 10;
        // this.projectDataJson.zersiedlung_rating = Math.round((parseFloat(this.projectDataJson.zersiedlung_rating) + Number.EPSILON) * 10) / 10;
        this.projectDataJson.nutzung_rating = Math.round((parseFloat(this.projectDataJson.nutzung_rating) + Number.EPSILON) * 10) / 10;
        this.projectDataJson.wirtschaftlichkeit_rating = Math.round((parseFloat(this.projectDataJson.wirtschaftlichkeit_rating) + Number.EPSILON) * 10) / 10;
        this.projectDataJson.innovation_rating = Math.round((parseFloat(this.projectDataJson.innovation_rating) + Number.EPSILON) * 10) / 10;

        console.log("projectDataJson", this.projectDataJson);

        // Sum Ratings. Make sure that the values are numbers
        const ratingKeys = ['betriebsenergie_rating', 'bauoekologie_rating', 'standort_rating',
              'nutzung_rating', 'wirtschaftlichkeit_rating',
              'innovation_rating'];
        let sum = 0;
        ratingKeys.forEach(key => {
            let value = this.projectDataJson[key];
            value = parseFloat(value);
            if(!isNaN(value)) { // Is valid number?
                sum += value;
            }
        });
        this.total = sum;

        // this.total =  parseFloat(this.projectDataJson.betriebsenergie_rating)
        //             + parseFloat(this.projectDataJson.bauoekologie_rating)
        //             + parseFloat(this.projectDataJson.standort_rating)
        //             + parseFloat(this.projectDataJson.zersiedlung_rating)
        //             + parseFloat(this.projectDataJson.nutzung_rating)
        //             + parseFloat(this.projectDataJson.wirtschaftlichkeit_rating)
        //             + parseFloat(this.projectDataJson.innovation_rating);

        this.total = Math.round((this.total + Number.EPSILON) * 10) / 10;

        document.addEventListener('DOMContentLoaded', (event) => {
            store.nextButtonDisabled = false;
        });

        this.anzahlBirds = Math.round(this.nachhaltigkeit);
    }
}
</script>
