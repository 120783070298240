<template>
    <div id="zersiedlung">
        <div>
            <div class="intro-text">{{ __('project.zersiedlung_erklaerung') }}</div>
            <div class="block">

                <div class="form-group">

                    <!-- Streuung -->
                    <label class="input-label">{{ __('project.streuung') }}</label>

                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline4" name="customRadioInline1"
                                class="custom-control-input" v-model="formData.streuung" value="4"
                                @change="changeRating()">
                        <label class="custom-control-label" for="customRadioInline4">
                            {{ __('project.siedlungsflaeche4') }}
                            <div
                                class="custom-tooltip" data-bs-custom-class="tooltipOpacity zersiedlung last" data-toggle="tooltip" data-bs-placement="right" data-html="true"
                                :title="tooltipDiv+__('project.zersiedlung_tooltip1')+tooltipDivEnd"
                            >
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>

                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline3" name="customRadioInline1"
                                class="custom-control-input" v-model="formData.streuung" value="3"
                                @change="changeRating()">
                        <label class="custom-control-label" for="customRadioInline3">
                            {{ __('project.siedlungsflaeche3') }}
                            <div
                                class="custom-tooltip" data-bs-custom-class="tooltipOpacity zersiedlung last" data-toggle="tooltip" data-bs-placement="right" data-html="true"
                                :title="tooltipDiv+__('project.zersiedlung_tooltip2')+tooltipDivEnd"
                            >
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>

                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline2" name="customRadioInline1"
                                class="custom-control-input" v-model="formData.streuung" value="2"
                                @change="changeRating()">
                        <label class="custom-control-label" for="customRadioInline2">
                            {{ __('project.siedlungsflaeche2') }}
                            <div
                                class="custom-tooltip" data-bs-custom-class="tooltipOpacity zersiedlung last" data-toggle="tooltip" data-bs-placement="right" data-html="true"
                                :title="tooltipDiv+__('project.zersiedlung_tooltip3')+tooltipDivEnd"
                            >
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>

                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline1" name="customRadioInline1"
                                class="custom-control-input" v-model="formData.streuung" value="1"
                                @change="changeRating()">
                        <label class="custom-control-label" for="customRadioInline1">
                            {{ __('project.siedlungsflaeche1') }}
                            <div
                                class="custom-tooltip" data-bs-custom-class="tooltipOpacity zersiedlung last" data-toggle="tooltip" data-bs-placement="right" data-html="true"
                                :title="tooltipDiv+__('project.zersiedlung_tooltip4')+tooltipDivEnd"
                            >
                                <img src="/images/info.png" width="17">
                            </div>
                        </label>
                    </div>

                </div>

                <div class="form-group">

                    <!-- Gebäudetyp -->
                    <label class="input-label">{{ __('project.gebaeudetyp') }}</label>

                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline10" name="customRadioInline2"
                                class="custom-control-input" v-model="formData.gebaeudetyp" value="6"
                                @change="changeRating()">
                        <label class="custom-control-label" for="customRadioInline10">{{ __('project.gebaeudetyp6') }}</label>
                    </div>

                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline9" name="customRadioInline2"
                                class="custom-control-input" v-model="formData.gebaeudetyp" value="5"
                                @change="changeRating()">
                        <label class="custom-control-label" for="customRadioInline9">{{ __('project.gebaeudetyp5') }}</label>
                    </div>

                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline8" name="customRadioInline2"
                                class="custom-control-input" v-model="formData.gebaeudetyp" value="4"
                                @change="changeRating()">
                        <label class="custom-control-label" for="customRadioInline8">{{ __('project.gebaeudetyp4') }}</label>
                    </div>

                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline7" name="customRadioInline2"
                                class="custom-control-input" v-model="formData.gebaeudetyp" value="3"
                                @change="changeRating()">
                        <label class="custom-control-label" for="customRadioInline7">{{ __('project.gebaeudetyp3') }}</label>
                    </div>

                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline6" name="customRadioInline2"
                                class="custom-control-input" v-model="formData.gebaeudetyp" value="2"
                                @change="changeRating()">
                        <label class="custom-control-label" for="customRadioInline6">{{ __('project.gebaeudetyp2') }}</label>
                    </div>

                    <div class="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="customRadioInline5" name="customRadioInline2"
                                class="custom-control-input" v-model="formData.gebaeudetyp" value="1"
                                @change="changeRating()">
                        <label class="custom-control-label" for="customRadioInline5">{{ __('project.gebaeudetyp1') }}</label>
                    </div>

                </div>
            </div>
        </div>

        <div class="points-box mb-2">
            <p class="title mb-2">{{ __('project.zersiedlung_ergebnis') }}</p>
            <p class="points mb-0">{{ formData.zersiedlung_rating }} {{ __('project.von') }} 5</p>

            <!-- Definitives Rating von Berater -->
            <div v-if="formData.zersiedlung_rating_definitiv" class="mt-3">
                <p class="title mb-2">{{ __('project.zersiedlung') }}-{{ __('project.ergebnis') }} definitiv</p>
                <p class="points mb-0">{{ zersiedlungsRatingDefinitivPoints }} {{ __('project.von') }} 5</p>
            </div>
        </div>

        <p class="mt-4" style="font-size: 1rem; opacity: 0.75;">{{ __('project.provisorisches_ergebnis') }}</p>

    </div>
</template>

<script>

import { store } from '../store.js'

export default {
    props: {
        project_data: {
            type: String
        }
    },
    data() {
        return {
            formData: {
                streuung: "",
                gebaeudetyp: "",
                zersiedlung_rating: 0,
                zersiedlung_rating_definitiv: false,
            },
            zersiedlungsRatingDefinitivPoints: false,
            tooltipDiv: '<div class="tooltip_value">',
            tooltipDivEnd: '</div>'
        }
    },
    computed: {
        projectDataJson() {
            if(!this.project_data) {
                return false;
            } else {
                return JSON.parse(this.project_data);
            }
        },
    },
    mounted() {
        this.formData.streuung = this.projectDataJson.streuung ?? "";
        this.formData.gebaeudetyp = this.projectDataJson.gebaeudetyp ?? "";
        this.formData.zersiedlung_rating = this.projectDataJson.zersiedlung_rating ?? 0;
        this.formData.zersiedlung_rating_definitiv = this.projectDataJson.zersiedlung_rating_definitiv;

        this.zersiedlungsRatingDefinitivPoints = this.projectDataJson.zersiedlung_rating_definitiv ?? false;

        document.addEventListener('DOMContentLoaded', (event) => {
            this.changeRating();
        });
    },
    methods: {
        changeRating: function() {

            console.log("changeRating()");
            console.log("this.formData.streuung", this.formData.streuung);
            console.log("this.formData.gebaeudetyp", this.formData.gebaeudetyp);

            this.formData.zersiedlung_rating = 0;

            if (this.formData.streuung !== "") {

                switch (this.formData.streuung.toString()) {
                    case "1":
                        this.formData.zersiedlung_rating += 1 * 0.5;
                        break;
                    case "2":
                        this.formData.zersiedlung_rating += 0.7 * 0.5;
                        break;
                    case "3":
                        this.formData.zersiedlung_rating += 0.4 * 0.5;
                        break;
                    case "4":
                        this.formData.zersiedlung_rating += 0.1 * 0.5;
                        break;
                    default:
                        this.formData.zersiedlung_rating += 0;
                }
            }

            if (this.formData.gebaeudetyp !== "") {

                var flaecheninanspruch = 0;
                var grundflaeche = this.projectDataJson.grundflaeche;
                var parzellen_flaeche = grundflaeche / 40 * 100; // Annahme es wird 40% der Fläche bebaut
                var ebf = this.projectDataJson.energiebezugsflaeche;
                var bewohner = this.projectDataJson.bewohner;
                var gebaeudetyp_faktor = 0;

                switch (this.formData.gebaeudetyp.toString()) {
                    case "1":
                        gebaeudetyp_faktor = 7;
                        break;
                    case "2":
                        gebaeudetyp_faktor = 5;
                        break;
                    case "3":
                        gebaeudetyp_faktor = 3;
                        break;
                    case "4":
                        gebaeudetyp_faktor = 5;
                        break;
                    case "5":
                        gebaeudetyp_faktor = 2;
                        break;
                    case "6":
                        gebaeudetyp_faktor = 1;
                        break;
                }

                // 250m2 = Benchmark
                flaecheninanspruch += 250 / parzellen_flaeche > 1 ? 1 * 0.25 : 250 / parzellen_flaeche * 0.25;
                flaecheninanspruch += gebaeudetyp_faktor * ebf / parzellen_flaeche / 3 > 1 ? 1 * 0.25 : gebaeudetyp_faktor * ebf / parzellen_flaeche / 3 * 0.25;
                flaecheninanspruch += 30 / (ebf / bewohner) > 1 ? 1 * 0.5 : 30 / (ebf / bewohner) * 0.5;

                this.formData.zersiedlung_rating += flaecheninanspruch * 0.5;

                var altersgewichtung = 2;
                if (this.projectDataJson.baujahr >= 1980) {
                    altersgewichtung = 0.75 + (1 - (Math.exp(3 - 40 / (this.projectDataJson.baujahr - 1979)) / (1 + Math.exp(3 - 40 / (this.projectDataJson.baujahr - 1979)))));
                }

                if (this.projectDataJson.baujahr < 1980 || (this.formData.zersiedlung_rating * altersgewichtung) > 1) {
                    this.formData.zersiedlung_rating = 1;
                } else {
                    this.formData.zersiedlung_rating *= altersgewichtung;
                }
            }

            if (this.formData.zersiedlung_rating >= 0.8) {
                this.formData.zersiedlung_rating = 5;
            }
            else if (this.formData.zersiedlung_rating > 0.5 && this.formData.zersiedlung_rating < 0.8) {
                this.formData.zersiedlung_rating = 3;
            }
            else {
                this.formData.zersiedlung_rating = 0;
            }

            store.zersiedlung_rating = this.formData.zersiedlung_rating;

            console.log("this.formData.zersiedlung_rating", this.formData.zersiedlung_rating)

            store.formData = this.formData;

            this.validateNextButton();
        },
        validateNextButton: function() {
            // If all criteria are met, enable next button
            if(this.formData.streuung !== '' && this.formData.gebaeudetyp !== '') {
                store.nextButtonDisabled = false;
            } else {
                store.nextButtonDisabled = true;
            }
        }
    }
}
</script>
